define('fontdrop/controllers/index', ['exports', 'fontdrop/statics/otFeatures', 'fontdrop/statics/languageData', 'fontdrop/statics/waterfallTexts'], function (exports, _otFeatures, _languageData, _waterfallTexts) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        application: Ember.inject.controller(),

        queryParams: ['funfont'],
        funfont: null,
        fontFile: 'assets/fonts/Rethink-Regular.otf',
        font: null,

        init: function init() {

            this.set('otFeatures', _otFeatures.default.otFeatures);
            this.set('languageData', _languageData.default.languageData);
            this.set('waterfallTexts', _waterfallTexts.default.waterfallTexts);

            Ember.run.next(this, function () {
                // Fun Font Query
                if (this.get('funfont')) {
                    var funfont = this.get('funfont');
                    var allFunFonts = this.get('funFonts');
                    var funfontFile = allFunFonts.find(function (x) {
                        return x.fontName === funfont;
                    }).fontPath;
                    this.set('fontFile', funfontFile);
                    document.getElementById("uploadedFont").innerHTML = '<style>' + '@font-face{' + 'font-family: preview;' + 'src: url(' + funfontFile + ');' + '}' + '.droppedFont {font-family: preview, AdobeBlank;}' + '#font-name .droppedFont {font-family: preview, AttributeFaux, AdobeBlank;}' + '</style>';

                    this.initFontLoad();
                } else {
                    this.initFontLoad();
                }
            });

            // Fun With Fonts files
            this.set('funFonts', [{ fontName: "Vestmar", fontPath: "assets/fonts/fun-with-fonts/VestmarVariable.ttf" }, { fontName: "NouvelleVague", fontPath: "assets/fonts/fun-with-fonts/NouvelleVague.otf" }, { fontName: "Buchstabenmuseum", fontPath: "assets/fonts/fun-with-fonts/Buchstabenmuseum_Schrift.otf" }, { fontName: "Arapix", fontPath: "assets/fonts/fun-with-fonts/29LTArapix-Regular.otf" }, { fontName: "Babetta", fontPath: "assets/fonts/babetta-regular-webfont.woff" }, { fontName: "ViaodaLibre", fontPath: "assets/fonts/fun-with-fonts/ViaodaLibre-Regular.ttf" }, { fontName: "KommuneInline", fontPath: "assets/fonts/fun-with-fonts/KommuneInline-Regular.otf" }, { fontName: "Icons", fontPath: "assets/fonts/fun-with-fonts/MaterialIcons-Regular.ttf" }, { fontName: "Chikki", fontPath: "assets/fonts/fun-with-fonts/Chikki-Regular.otf" }, { fontName: "WHOA", fontPath: "assets/fonts/fun-with-fonts/WHOA-Variable-Spine-v0_3.ttf" }, { fontName: "Apparat", fontPath: "assets/fonts/fun-with-fonts/ApparatSemiCond-Heavy.woff" }, { fontName: "funt", fontPath: "assets/fonts/fun-with-fonts/AnUnfinishedFunt-OpenLine.otf" }]);
        },

        initFontLoad: function initFontLoad() {
            var c = this,
                file = c.get('fontFile');
            /* global opentype */
            opentype.load(file, function (err, font) {
                if (err) {
                    alert('Font could not be loaded: ' + err);
                } else {
                    // var ctx = document.getElementById('fontName').getContext('2d');
                    // Construct a Path object containing the letter shapes of the given text.
                    // The other parameters are x, y and fontSize.
                    // Note that y is the position of the baseline.
                    // var path = font.getPath(font.names.fontFamily.en, 0, 200, c.get('fontSize'));
                    // If you just want to draw the text you can also use font.draw(ctx, text, x, y, fontSize).
                    // path.draw(ctx);
                    c.set('font', font);
                    //console.log("Font name is: " + font.names.fontFamily.en);
                    //console.log(font);
                }
            });
        },

        actions: {
            changeFont: function changeFont(font) {
                //console.log('changeFont')
                //this.set('font',null);
                this.set('font', font);
                //console.log(this.get('font'))
            }
        }

    });
});