define('fontdrop/controllers/compare', ['exports', 'fontdrop/statics/compareTexts', 'fontdrop/statics/waterfallTexts'], function (exports, _compareTexts, _waterfallTexts) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        application: Ember.inject.controller(),

        fontOne: null,

        init: function init() {
            this.set('compareTexts', _compareTexts.default.compareTexts);
            this.set('waterfallTexts', _waterfallTexts.default.waterfallTexts);
        },

        actions: {
            changeFontOne: function changeFontOne(fontOne) {
                this.set('fontOne', fontOne);
            }
        }

    });
});