define('fontdrop/helpers/format-charcode', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCharcode = formatCharcode;

  // this helper is used to transform charcode to a unicode letter (string)!

  function formatCharcode(charcode /*, hash*/) {

    return String.fromCharCode.apply(null, charcode);
  }

  exports.default = Ember.Helper.helper(formatCharcode);
});