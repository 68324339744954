define('fontdrop/components/font-otfeatures', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		classNames: ['otFeaturesArea'],

		otFeaDetected: [],
		feature: [],
		featureOn: [],
		featureOff: [],
		featureSettingsOn: [],
		featureSettingsOff: [],

		detectOT: function () {
			var zeroFeatures = [];
			this.set('otFeaDetected', zeroFeatures);
			// Get OT-Features from GSUB and GPOS table in font
			if (typeof this.get('font.tables.gsub') !== "undefined" || typeof this.get('font.tables.gpos') !== "undefined") {
				var parsedFeatures = [];
				if (typeof this.get('font.tables.gsub') !== "undefined") {
					// gsub
					var gsubFeatures = this.get('font.tables.gsub.features');
					Object.keys(gsubFeatures).forEach(function (key) {
						var val = { 'tag': gsubFeatures[key]["tag"] };
						parsedFeatures.push(val);
					});
				}if (typeof this.get('font.tables.gpos') !== "undefined") {
					// gpos
					var gposFeatures = this.get('font.tables.gpos.features');
					Object.keys(gposFeatures).forEach(function (key) {
						var val = { 'tag': gposFeatures[key]["tag"] };
						parsedFeatures.push(val);
					});
				}
				//
				//
				// New Approach
				// This will also keep the unknown and custom features!
				// Filter duplicates from parsedFeatures ...
				var parsedFeaUniq = parsedFeatures.filter(function (thing, index, self) {
					return index === self.findIndex(function (t) {
						return t.tag === thing.tag;
					});
				});

				// ... and add otFeatures database tag names and info
				// ToDO for wildcard features ss## and cv##
				var otFeatures = this.get('otFeatures');

				parsedFeaUniq.map(function (e) {
					var temp = otFeatures.find(function (element) {
						return element.tag === e.tag;
					});
					if (temp) {
						if (temp.name) {
							e.name = temp.name;
						} else {
							e.name = 'Custom Feature';
						}
						if (temp.desc) {
							e.desc = temp.desc;
						}
						if (temp.default) {
							e.default = temp.default;
						}
						if (temp.hide) {
							e.hide = temp.hide;
						}
						return e;
					}
				});
				// ... and remove features with hide=1 ...
				var parsedFeaUniqClean = parsedFeaUniq.filter(function (e) {
					return e.hide !== 1;
				});
				// ... and set default features On!
				var featuresOn = [];
				for (var i = 0; i < parsedFeaUniqClean.length; i++) {
					if (parsedFeaUniqClean[i].default === 1) {
						var fea = parsedFeaUniqClean[i].tag;
						featuresOn.push(fea);
					}
					this.set('featureOn', featuresOn);
				}
				// console.log('parsedFeaturesUniqClean');
				// console.log(parsedFeaUniqClean);

				this.set('otFeaDetected', parsedFeaUniqClean);
				this.featureSettings();
			}
		}.observes('font'),

		featureSettings: function featureSettings() {
			var featuresOn = this.get('featureOn');
			var featuresOff = this.get('featureOff');
			var featuresStyleOn = featuresOn.slice(0);
			var featuresStyleOff = featuresOff.slice(0);
			this.set('featureSettingsOn', null);
			this.set('featureSettingsOff', null);
			for (var on = 0; on < featuresStyleOn.length; on++) {
				featuresStyleOn[on] = '"' + featuresStyleOn[on] + '" on';
				this.set('featureSettingsOn', featuresStyleOn);
			}
			for (var off = 0; off < featuresStyleOff.length; off++) {
				featuresStyleOff[off] = '"' + featuresStyleOff[off] + '" off';
				this.set('featureSettingsOff', featuresStyleOff);
			}
			// console.log('featureSettingsOn: ' +featuresStyleOn);
			// console.log('featureSettingsOff: ' +featuresStyleOff);
		},


		actions: {
			switchOTfeature: function switchOTfeature(feature) {
				this.set('feature', feature);
				var featuresOn = this.get('featureOn');
				var featuresOff = this.get('featureOff');
				var on = featuresOn.indexOf(feature);
				var off = featuresOff.indexOf(feature);
				if (on === -1) {
					featuresOn.push(feature);
					featuresOff.splice(on, 1);
				} else if (off === -1) {
					featuresOff.push(feature);
					featuresOn.splice(on, 1);
				} else {
					featuresOn.push(feature);
				}
				this.featureSettings();
			}
		}
	});
});