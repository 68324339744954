define("fontdrop/helpers/check-url", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.checkUrl = checkUrl;
	function checkUrl(url /*, hash*/) {

		if (!/^(f|ht)tps?:\/\//i.test(url)) {
			url = "http://" + url;
		}
		return url;
	}

	exports.default = Ember.Helper.helper(checkUrl);
});