define('fontdrop/components/font-drop', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		tagName: 'div',
		classNames: 'dropzone'.w(),
		classNameBindings: 'isDragging isDisabled:is-disabled'.w(),
		attributeBindings: 'data-uploader'.w(),
		'data-uploader': 'true',
		isDisabled: false,

		fileTypes: ['otf', 'ttf', 'woff', 'woff2'],
		fileExtension: false,

		error: null,
		filename: null,

		dragEnter: function dragEnter(event) {
			event.preventDefault();
			this.set('isDragging', true);
			document.getElementById("fontDropBody").classList.add('dropOn');
		},
		dragOver: function dragOver(event) {
			event.preventDefault();
			this.set('isDragging', true);
			document.getElementById("fontDropBody").classList.add('dropOn');
		},
		dragLeave: function dragLeave(event) {
			event.preventDefault();
			this.set('isDragging', false);
			document.getElementById("fontDropBody").classList.remove('dropOn');
		},
		drop: function drop(event) {
			event.preventDefault();
			this.set('isDragging', false);
			document.getElementById("fontDropBody").classList.remove('dropOn');
			var file = event.dataTransfer.files[0];
			var filesize = Math.floor(Math.log(file.size) / Math.log(1024));
			var nicerFileSize = (file.size / Math.pow(1024, filesize)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][filesize];
			this.set('fileExtension', file.name.split('.').pop().toLowerCase());
			var checkFileType = this.get('fileTypes').indexOf(this.get('fileExtension')) > -1;
			if (checkFileType) {
				//yes
				this.set('filename', file.name);
				document.getElementById("message").innerHTML = file.name;
				document.getElementById("fileSize").innerHTML = ', ' + nicerFileSize;
				document.getElementById("highlightBar").classList.add('hideHighlight');
				this.set('isDisabled', true);
				// this.sendAction('fileInputChanged', file); // (was ist das?)
				this.parseFont(file);
				this.viewFont(file);
			} else {
				document.getElementById("message").innerHTML = '<span class="warning label">Only works with OTF, TTF, WOFF and WOFF2 files.</span>';
			}
		},

		parseFont: function parseFont(fontfile) {
			var reader = new FileReader();
			var _this = this,
			    file = void 0;
			reader.onload = function (e) {
				var error = _this.get('error');
				console.log(error);
				/* check woff2 and decompress */
				if (_this.get('fileExtension') === "woff2") {
					file = Uint8Array.from(Module.decompress(e.target.result)).buffer;
				} else {
					file = e.target.result;
				}
				/* global opentype */
				var font = opentype.parse(file);
				_this.attrs.send(font);
			};
			reader.onerror = function (err) {
				_this.set('error', err);
				console.log('Error: ' + err.toString());
			};
			document.getElementById("parsingFont").classList.toggle('parsingDone');
			reader.readAsArrayBuffer(fontfile);
		},
		viewFont: function viewFont(fontfile) {
			var reader = new FileReader();
			reader.onload = function (e) {
				document.getElementById("uploadedFont").innerHTML = '<style>' + '@font-face{' + 'font-family: preview;' + 'src: url(' + e.target.result + ');' + '}' + '.droppedFont {font-family: preview, AdobeBlank;}' + '#font-name .droppedFont {font-family: preview, AttributeFaux, AdobeBlank;}' + '</style>';
			};
			reader.readAsDataURL(fontfile);
		},

		actions: {
			uploadFont: function uploadFont(e) {
				var file = e.target.files[0];
				var filesize = Math.floor(Math.log(file.size) / Math.log(1024));
				var nicerFileSize = (file.size / Math.pow(1024, filesize)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][filesize];
				this.set('fileExtension', file.name.split('.').pop().toLowerCase());
				var checkFileType = this.get('fileTypes').indexOf(this.get('fileExtension')) > -1;
				if (checkFileType) {
					this.set('filename', file.name);
					document.getElementById("message").innerHTML = file.name;
					document.getElementById("fileSize").innerHTML = ', ' + nicerFileSize;
					document.getElementById("highlightBar").classList.add('hideHighlight');
					this.set('isDisabled', true);
					// this.sendAction('fileInputChanged', file); // (was ist das?)
					this.parseFont(file);
					this.viewFont(file);
				} else {
					document.getElementById("message").innerHTML = '<span class="warning label">Only works with OTF, TTF, WOFF and WOFF2 files.</span>';
				}
			}
		}

	});
});