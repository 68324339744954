define('fontdrop/components/font-data', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		classNames: ['nameingArea'],

		didInsertElement: function didInsertElement() {
			this._super();
			Ember.run.scheduleOnce('afterRender', this, this.readMore);
		},

		readMore: function readMore() {
			var showChar = 100; // How many characters are shown by default
			var ellipsestext = "...";
			var moretext = "Show more >";
			var lesstext = "Show less";

			this.$('.more').each(function () {
				var content = this.$('.more').html();

				if (content.length > showChar) {

					var c = content.substr(0, showChar);
					var h = content.substr(showChar, content.length - showChar);

					var html = c + '<span class="moreellipses">' + ellipsestext + '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink">' + moretext + '</a></span>';

					this.$('.more').html(html);
				}
			});

			this.$(".morelink").click(function () {
				if (this.$(".morelink").hasClass("less")) {
					this.$(".morelink").removeClass("less");
					this.$(".morelink").html(moretext);
				} else {
					this.$(".morelink").addClass("less");
					this.$(".morelink").html(lesstext);
				}
				this.$(".morelink").parent().prev().toggle();
				this.$(".morelink").prev().toggle();
				return false;
			});
		}

	});
});