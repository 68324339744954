define('fontdrop/components/compare-drop-two', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		tagName: 'div',
		classNames: 'dropzone'.w(),
		classNameBindings: 'isDragging isDisabled:is-disabled'.w(),
		attributeBindings: 'data-uploader'.w(),
		'data-uploader': 'true',
		isDisabled: false,

		fileTypes: ['otf', 'ttf', 'woff', 'woff2'],
		fileExtension: false,

		error: null,
		filenameTwo: null,
		fontFullNameTwo: null,
		fontVersionTwo: null,

		dragEnter: function dragEnter(event) {
			event.preventDefault();
			this.set('isDragging', true);
			document.getElementById("fontDropBody").classList.add('dropOn');
		},
		dragOver: function dragOver(event) {
			event.preventDefault();
			this.set('isDragging', true);
			document.getElementById("fontDropBody").classList.add('dropOn');
		},
		dragLeave: function dragLeave(event) {
			event.preventDefault();
			this.set('isDragging', false);
			document.getElementById("fontDropBody").classList.remove('dropOn');
		},
		drop: function drop(event) {
			event.preventDefault();
			this.set('isDragging', false);
			document.getElementById("fontDropBody").classList.remove('dropOn');
			var file = event.dataTransfer.files[0];
			this.set('fileExtension', file.name.split('.').pop().toLowerCase());
			var checkFileType = this.get('fileTypes').indexOf(this.get('fileExtension')) > -1;
			if (checkFileType) {
				//yes
				this.set('filenameTwo', file.name);
				document.getElementById("messageTwo").innerHTML = file.name;
				this.set('isDisabled', true);
				this.sendAction('fileInputChanged', file);
				this.parseFontTwo(file);
				this.viewFontTwo(file);
			} else {
				document.getElementById("messageTwo").innerHTML = '<span class="warning label">Sorry but only works with OTF, TTF or WOFF files.</span>';
			}
		},
		parseFontTwo: function parseFontTwo(fontfiletwo) {
			var reader = new FileReader();
			var _this = this,
			    file = void 0;
			reader.onload = function (e) {
				var error = _this.get('error');
				console.log(error);
				/* check woff2 and decompress */
				if (_this.get('fileExtension') === "woff2") {
					file = Uint8Array.from(Module.decompress(e.target.result)).buffer;
				} else {
					file = e.target.result;
				}
				/* global opentype */
				var fontTwo = opentype.parse(file);
				_this.set('fontFullNameTwo', fontTwo.names.fullName.en);
				_this.set('fontVersionTwo', fontTwo.tables.name.version.en);
			};
			reader.onerror = function (err) {
				_this.set('error', err);
			};
			reader.readAsArrayBuffer(fontfiletwo);
		},
		viewFontTwo: function viewFontTwo(fontfiletwo) {
			var reader = new FileReader();
			reader.onload = function (e) {
				document.getElementById("uploadedFontCompareTwo").innerHTML = '<style>' + '@font-face{' + 'font-family: compareTwo;' + 'src: url(' + e.target.result + ');' + '}' + '.compareFontTwo {font-family: compareTwo, AdobeBlank;}' + '</style>';
			};
			reader.readAsDataURL(fontfiletwo);
		},

		actions: {
			uploadFontCompareTwo: function uploadFontCompareTwo(e) {
				var file = e.target.files[0];
				this.set('fileExtension', file.name.split('.').pop().toLowerCase());
				var checkFileType = this.get('fileTypes').indexOf(this.get('fileExtension')) > -1;
				if (checkFileType) {
					this.set('filenameTwo', file.name);
					document.getElementById("messageTwo").innerHTML = file.name;
					this.set('isDisabled', true);
					this.sendAction('fileInputChanged', file);
					this.parseFontTwo(file);
					this.viewFontTwo(file);
				} else {
					document.getElementById("messageTwo").innerHTML = '<span class="warning label">Sorry but only works with OTF, TTF or WOFF (not WOFF2)!</span>';
				}
			}
		}

	});
});