define("fontdrop/helpers/format-unicode", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.formatUnicode = formatUnicode;
	function formatUnicode(unicodes /*, hash*/) {
		return unicodes.map(function (unicode) {
			unicode = parseInt(unicode);

			if (Number.isNaN(Number(unicode))) {
				return "none";
			} else {

				unicode = unicode.toString(16);

				if (unicode.length > 4) {
					return ("000000" + unicode.toUpperCase()).substr(-6);
				} else {
					return ("0000" + unicode.toUpperCase()).substr(-4);
				}
			}
		}).join(',');
	}

	exports.default = Ember.Helper.helper(formatUnicode);
});