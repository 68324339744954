define('fontdrop/components/info-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    elementId: 'info-button',

    // get Foundation JS work
    didInsertElement: function didInsertElement() {
      this.$().foundation(); //or Ember.$(document).foundation(); 
    }

  });
});