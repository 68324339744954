define('fontdrop/components/font-preview', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        text: 'Hello World!',
        drawPoints: false,
        drawMetrics: false,
        fontSize: 96,
        kerning: true,

        watchText: function () {
            this.send("renderText");
        }.observes('text'),

        watchDrawPoints: function () {
            this.send("renderText");
        }.observes('drawPoints'),

        watchDrawMetrics: function () {
            this.send("renderText");
        }.observes('drawMetrics'),

        watchKerning: function () {
            this.send("renderText");
        }.observes('kerning'),

        watchFont: function () {
            this.send("renderText");
        }.observes('font'),

        actions: {
            renderText: function renderText() {
                var textToRender = this.get('text');
                var font = this.get('font');
                var previewCtx = document.getElementById('preview').getContext('2d');
                previewCtx.clearRect(0, 0, 940, 300);
                font.draw(previewCtx, textToRender, 0, 200, this.get('fontSize'), { kerning: this.get('kerning') });
                if (this.get('drawPoints')) {
                    font.drawPoints(previewCtx, textToRender, 0, 200, this.get('fontSize'), { kerning: this.get('kerning') });
                }
                if (this.get('drawMetrics')) {
                    font.drawMetrics(previewCtx, textToRender, 0, 200, this.get('fontSize'), { kerning: this.get('kerning') });
                }
            },
            updateFontSize: function updateFontSize(value) {
                this.set('fontSize', value);
                this.send("renderText");
            }
        }
    });
});