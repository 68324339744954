define("fontdrop/components/compare-info", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            this.$().foundation(); //or Ember.$(document).foundation();
        },


        compareText: "Traditionally, text is composed to create a readable, coherent, and visually satisfying typeface that works invisibly, without the awareness of the reader. Even distribution of typeset material, with a minimum of distractions and anomalies, is aimed at producing clarity and transparency. Choice of typeface(s) is the primary aspect of text typography—prose fiction, non-fiction, editorial, educational, religious, scientific, spiritual, and commercial writing all have differing characteristics and requirements of appropriate typefaces (and their fonts or styles). For historic material, established text typefaces frequently are chosen according to a scheme of historical genre acquired by a long process of accretion, with considerable overlap among historical periods. Contemporary books are more likely to be set with state-of-the-art “text roman” or “book romans” typefaces with serifs and design values echoing present-day design arts, which are closely based on traditional models such as those of Nicolas Jenson, Francesco Griffo (a punchcutter who created the model for Aldine typefaces), and Claude Garamond. [Source: Wikipedia, Typography]",
        compareCompareTexts: null,

        waterfallLine: "The quick brown fox jumps over the lazy dog.",
        waterfallFontSizes: [96, 84, 72, 60, 48, 36, 30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8],

        actions: {
            setCompare: function setCompare(id) {
                var result = this.get('compareTexts').findBy('id', id);
                this.set('compareText', result.sample);
                this.set('direction', result.direction);
            },
            setWaterfall: function setWaterfall(id) {
                var result = this.get('waterfallTexts').findBy('id', id);
                this.set('waterfallLine', result.sample);
            },
            updateWaterfallText: function updateWaterfallText(text) {
                this.set('waterfallLine', text);
            }
        }

    });
});