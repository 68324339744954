define('fontdrop/components/font-languages', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Component.extend({

		classNames: ['languageDataArea'],
		fontLanguages: [],
		langLength: null,

		detectLanguage: function () {
			// Get unicode list from font
			var glyphs = this.get('font.glyphs.glyphs');
			var fontUniIndex = [];
			Object.keys(glyphs).forEach(function (key) {
				var val = glyphs[key]['unicodes'];
				if (val !== 'undefined' && val.length > 0) {
					fontUniIndex.push.apply(fontUniIndex, _toConsumableArray(val));
				}
			});
			var langData = this.get('languageData');
			var allLanguages = [];
			// Get langindex for languages and check if in fonts unicode list
			Object.keys(langData).forEach(function (key) {
				var langindex = langData[key]["langindex"];
				// Function for checkin if something matches
				var isSuperset = langindex.every(function (val) {
					return fontUniIndex.indexOf(val) >= 0;
				});

				var langName = langData[key]["name"];
				if (isSuperset === true) {
					allLanguages.push(langName);
				}
			});
			this.set('fontLanguages', allLanguages);
			//console.log("All detected languages in font: " + allLanguages);
		}.observes('font')

	});
});