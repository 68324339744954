define('fontdrop/components/scroll-to-top', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		scrollToTop: function () {
			Ember.run.scheduleOnce('afterRender', this, function () {

				this.$("#scroll-to-top").hide();

				this.$(window).scroll(function () {
					if (this.$(this).scrollTop() > 200) {
						this.$('#scroll-to-top').fadeIn('slow');
					} else {
						this.$('#scroll-to-top').fadeOut('slow');
					}
				});
			});
		}.on('didInsertElement'),

		willDestroyElement: function willDestroyElement() {
			//remove handlers like this.$().off(...)
		},

		actions: {
			toTop: function toTop() {
				Ember.$(window).scrollTop(0);
			}
		}

	});
});