define('fontdrop/components/compare-characters', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		classNames: ['compareCharacters'],
		allCharacters: [],

		getCharacters: function () {
			var glyphs = this.get('fontOne.glyphs.glyphs');
			var fontUniIndex = [];
			Object.keys(glyphs).forEach(function (key) {
				var val = glyphs[key]['unicodes'];
				if (val !== 'undefined' && val.length > 0) {
					fontUniIndex.push(String.fromCharCode(val[0]));
				}
			});
			this.set('allCharacters', fontUniIndex);
		}.observes('fontOne')

	});
});