define('fontdrop/components/glyph-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        classNames: ['glyphItem'],
        width: 500,
        height: 500,
        attributeBindings: ['width', 'height'],

        // get Foundation JS work
        didInsertElement: function didInsertElement() {
            this.$().foundation(); //or Ember.$(document).foundation();
        },

        drawPathWithArrows: function drawPathWithArrows(ctx, path) {
            var _this = this;
            var i, cmd, x1, y1, x2, y2;
            var arrows = [];
            ctx.beginPath();
            for (i = 0; i < path.commands.length; i += 1) {
                cmd = path.commands[i];
                if (cmd.type === 'M') {
                    if (x1 !== undefined) {
                        arrows.push([ctx, x1, y1, x2, y2]);
                    }
                    ctx.moveTo(cmd.x, cmd.y);
                } else if (cmd.type === 'L') {
                    ctx.lineTo(cmd.x, cmd.y);
                    x1 = x2;
                    y1 = y2;
                } else if (cmd.type === 'C') {
                    ctx.bezierCurveTo(cmd.x1, cmd.y1, cmd.x2, cmd.y2, cmd.x, cmd.y);
                    x1 = cmd.x2;
                    y1 = cmd.y2;
                } else if (cmd.type === 'Q') {
                    ctx.quadraticCurveTo(cmd.x1, cmd.y1, cmd.x, cmd.y);
                    x1 = cmd.x1;
                    y1 = cmd.y1;
                } else if (cmd.type === 'Z') {
                    arrows.push([ctx, x1, y1, x2, y2]);
                    ctx.closePath();
                }
                x2 = cmd.x;
                y2 = cmd.y;
            }
            if (path.fill) {
                ctx.fillStyle = path.fill;
                ctx.fill();
            }
            if (path.stroke) {
                ctx.strokeStyle = path.stroke;
                ctx.lineWidth = path.strokeWidth;
                ctx.stroke();
            }
            ctx.fillStyle = '#000000';
            arrows.forEach(function (arrow) {
                _this.drawArrow.apply(null, arrow);
            });
        },

        drawArrow: function drawArrow(ctx, x1, y1, x2, y2) {
            var arrowLength = 10,
                arrowAperture = 4;
            var dx = x2 - x1,
                dy = y2 - y1,
                segmentLength = Math.sqrt(dx * dx + dy * dy),
                unitx = dx / segmentLength,
                unity = dy / segmentLength,
                basex = x2 - arrowLength * unitx,
                basey = y2 - arrowLength * unity,
                normalx = arrowAperture * unity,
                normaly = -arrowAperture * unitx;
            ctx.beginPath();
            ctx.moveTo(x2, y2);
            ctx.lineTo(basex + normalx, basey + normaly);
            ctx.lineTo(basex - normalx, basey - normaly);
            ctx.lineTo(x2, y2);
            ctx.closePath();
            ctx.fill();
        },

        renderGlyph: function renderGlyph() {
            var glyphIndex = this.get('index');
            var cellMarkSize = 4;
            var canvas = document.getElementById('glyph-detail');
            var font = this.get('font');
            var cellWidth = this.get('width');
            var cellHeight = this.get('height');
            var fontScale,
                fontSize,
                fontBaseline,
                glyphSize,
                glyphBaseline,
                glyphMargin = 5;
            var head = font.tables.head,
                glyphW = cellWidth - glyphMargin * 2,
                glyphH = cellHeight - glyphMargin * 2,
                maxHeight = head.yMax - head.yMin;
            var glyphScale = Math.min(glyphW / (head.xMax - head.xMin), glyphH / maxHeight);
            glyphSize = glyphScale * font.unitsPerEm;
            glyphBaseline = glyphMargin + glyphH * head.yMax / maxHeight;
            fontScale = Math.min(cellWidth / (head.xMax - head.xMin), cellHeight / maxHeight);
            var ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, cellWidth, cellHeight);
            if (glyphIndex >= font.numGlyphs) {
                return;
            }

            // styling of ...
            ctx.fillStyle = '#000';
            ctx.font = '11px sans-serif';
            ctx.fillText(glyphIndex, 1, cellHeight - 1);
            var glyph = font.glyphs.get(glyphIndex),
                glyphWidth = glyph.advanceWidth * fontScale,
                xmin = (cellWidth - glyphWidth) / 2,
                xmax = (cellWidth + glyphWidth) / 2,
                x0 = xmin;

            // styling of ...
            ctx.fillStyle = '#000';
            ctx.fillRect(xmin - cellMarkSize + 1, fontBaseline, cellMarkSize, 1);
            ctx.fillRect(xmin, fontBaseline, 1, cellMarkSize);
            ctx.fillRect(xmax, fontBaseline, cellMarkSize, 1);
            ctx.fillRect(xmax, fontBaseline, 1, cellMarkSize);

            // styling of ...
            ctx.fillStyle = '#000';
            glyph.draw(ctx, x0, fontBaseline, fontSize);
            var pixelRatio = 1;
            var width = canvas.width / pixelRatio;
            var height = canvas.height / pixelRatio;
            ctx.clearRect(0, 0, width, height);
            if (glyphIndex < 0) {
                return;
            }
            var markSize = 10;

            // draw horizontal metric lines
            function hline(text, yunits) {
                var ypx = glyphBaseline - yunits * glyphScale;
                ctx.fillText(text, 2, ypx + 3);
                ctx.fillRect(70, ypx, width, 1);
            }
            ctx.clearRect(0, 0, width, height);
            ctx.fillStyle = '#e1e1e1';
            hline('Baseline', 0); // Baseline
            // hline('yMax', font.tables.head.yMax); //yMax
            // hline('yMin', font.tables.head.yMin); //yMin
            hline('Ascender', font.tables.hhea.ascender); // Ascender
            hline('Descender', font.tables.hhea.descender); // Desender
            // hline('', font.tables.os2.sTypoAscender); // Typo Ascender
            // hline('', font.tables.os2.sTypoDescender); // Typo Descender
            hline('x-Height', font.tables.os2.sxHeight); // x-Height
            hline('Caps', font.tables.os2.sCapHeight); // Caps Height

            // styling of metric values
            ctx.fillStyle = '#999999';
            ctx.fillRect(xmin - markSize + 1, glyphBaseline, markSize, 1);
            ctx.fillRect(xmin, glyphBaseline, 1, markSize);
            ctx.fillRect(xmax, glyphBaseline, markSize, 1);
            ctx.fillRect(xmax, glyphBaseline, 1, markSize);
            ctx.textAlign = 'center';
            ctx.fillText('1', xmin, glyphBaseline + markSize + 10);
            ctx.fillText(glyph.advanceWidth, xmax, glyphBaseline + markSize + 10);

            // styling of the glyph
            ctx.fillStyle = '#000';
            var path = glyph.getPath(x0, glyphBaseline, glyphSize);
            // color of glyph fill
            path.fill = '#e1e1e1';
            // color of glyph contour
            path.stroke = '#840084';
            path.strokeWidth = 1;
            this.drawPathWithArrows(ctx, path);
            glyph.drawPoints(ctx, x0, glyphBaseline, glyphSize);
        },

        displayGlyphData: function displayGlyphData() {
            var glyphIndex = this.get('index');
            var container = document.getElementById('glyph-item-data');
            var font = this.get('font');
            if (glyphIndex < 0) {
                container.innerHTML = '';
                return;
            }
            var glyph = font.glyphs.get(glyphIndex),
                html = '<dl>';
            html += '<dt>name</dt><dd>' + glyph.name + '</dd>';
            if (glyph.unicodes.length > 0) {
                var theUnicode = glyph.unicodes.map(function (unicode) {
                    unicode = unicode.toString(16);
                    if (unicode.length > 4) {
                        return ("000000" + unicode.toUpperCase()).substr(-6);
                    } else {
                        return ("0000" + unicode.toUpperCase()).substr(-4);
                    }
                }).join(', ');
                // add search for Unicode on Ecosia ↗
                html += '<dt>unicode</dt><dd>' + theUnicode + ' <a href="https://www.ecosia.org/search?method=index&q=unicode ' + theUnicode + '" title="Search for Unicode on Ecosia" target="_blank" rel="noopener">↗</a> ';
                html += '</dd>';
            }
            html += '<dt>index</dt><dd>' + glyph.index + '</dd>';
            if (glyph.xMin !== 0 || glyph.xMax !== 0 || glyph.yMin !== 0 || glyph.yMax !== 0) {
                html += '<dt>xMin</dt><dd>' + glyph.xMin + '</dd>' + '<dt>xMax</dt><dd>' + glyph.xMax + '</dd>' + '<dt>yMin</dt><dd>' + glyph.yMin + '</dd>' + '<dt>yMax</dt><dd>' + glyph.yMax + '</dd>';
            }
            html += '<dt>advanceWidth</dt><dd>' + glyph.advanceWidth + '</dd>';
            if (glyph.leftSideBearing !== undefined) {
                html += '<dt>leftSideBearing</dt><dd>' + glyph.leftSideBearing + '</dd>';
            }
            html += '</dl>';
            if (glyph.numberOfContours > 0) {
                var contours = glyph.getContours();
                html += '<span>Contours data</span><div id="glyph-contours">' + contours.map(function (contour) {
                    return '<pre class="contour">' + contour.map(function (point) {
                        return '<span class="' + (point.onCurve ? 'on' : 'off') + 'curve">x=' + point.x + ' y=' + point.y + '</span>';
                    }).join('\n') + '</pre>';
                }).join('\n') + '</div>';
            } else if (glyph.isComposite) {
                html += '<br/><span>This composite glyph is a combination of :</span><br/><ul><li>' + glyph.components.map(function (component) {
                    if (component.matchedPoints === undefined) {
                        return 'glyph ' + component.glyphIndex + ' at dx=' + component.dx + ', dy=' + component.dy;
                    } else {
                        return 'glyph ' + component.glyphIndex + ' at matchedPoints=[' + component.matchedPoints + ']';
                    }
                }).join('</li><li>') + '</li></ul>';
            } else if (glyph.path) {
                html += '<spn>Path data</span><br/><pre>  ' + glyph.path.commands.map(function (cmd) {
                    var str = '<strong>' + cmd.type + '</strong> ' + (cmd.x !== undefined ? 'x=' + cmd.x + ' y=' + cmd.y + ' ' : '') + (cmd.x1 !== undefined ? 'x1=' + cmd.x1 + ' y1=' + cmd.y1 + ' ' : '') + (cmd.x2 !== undefined ? 'x2=' + cmd.x2 + ' y2=' + cmd.y2 : '');
                    return str;
                }).join('\n  ') + '\n</pre>';
            }

            container.innerHTML = html;
        },

        click: function click(e) {
            e.preventDefault();
            this.renderGlyph();
            this.displayGlyphData();
            Ember.$('#glyphModal').foundation('reveal', 'open');
        }

    });
});