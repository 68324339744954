define('fontdrop/components/glyph-item-glyph', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super();
            var index = this.get('index');
            this.set('elementId', 'g' + index);
            this.set('width', 250);
            this.set('height', 260);
        },


        didInsertElement: function didInsertElement() {
            this.renderGlyph();
        },

        tagName: 'canvas',
        width: 250,
        height: 260,
        attributeBindings: ['width', 'height'],

        renderGlyph: function renderGlyph() {
            var glyphIndex = this.get('index');
            var cellMarkSize = 8;
            var canvas = document.getElementById('g' + glyphIndex);
            var font = this.get('font');
            var cellWidth = this.get('width');
            var cellHeight = this.get('height');
            var fontScale,
                fontSize,
                fontBaseline,
                glyphSize,
                glyphBaseline,
                glyphMargin = 5;
            var head = font.tables.head,
                glyphW = cellWidth - glyphMargin * 2,
                glyphH = cellHeight - glyphMargin * 2,
                maxHeight = head.yMax - head.yMin;
            var glyphScale = Math.min(glyphW / (head.xMax - head.xMin), glyphH / maxHeight);
            glyphSize = glyphScale * font.unitsPerEm;
            glyphBaseline = glyphMargin + glyphH * head.yMax / maxHeight;
            fontScale = Math.min(cellWidth / (head.xMax - head.xMin), cellHeight / maxHeight);
            var ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, cellWidth, cellHeight);
            if (glyphIndex >= font.numGlyphs) {
                return;
            }

            // styling of ...
            ctx.fillStyle = '#606060';
            ctx.font = '16px sans-serif';
            ctx.fillText(glyphIndex, 1, cellHeight - 1);
            var glyph = font.glyphs.get(glyphIndex),
                glyphWidth = glyph.advanceWidth * fontScale,
                xmin = (cellWidth - glyphWidth) / 2,
                xmax = (cellWidth + glyphWidth) / 2,
                x0 = xmin;

            // styling of ...
            ctx.fillStyle = '#a0a0a0';
            ctx.fillRect(xmin - cellMarkSize + 1, fontBaseline, cellMarkSize, 1);
            ctx.fillRect(xmin, fontBaseline, 1, cellMarkSize);
            ctx.fillRect(xmax, fontBaseline, cellMarkSize, 1);
            ctx.fillRect(xmax, fontBaseline, 1, cellMarkSize);

            // styling of ...
            ctx.fillStyle = '#000000';
            glyph.draw(ctx, x0, fontBaseline, fontSize);
            var pixelRatio = 1;
            var width = canvas.width / pixelRatio;
            var height = canvas.height / pixelRatio;
            ctx.clearRect(0, 0, width, height);
            if (glyphIndex < 0) {
                return;
            }
            var markSize = 10;

            // styling of metric values
            ctx.fillStyle = '#CCC';
            ctx.fillRect(xmin - markSize + 1, glyphBaseline, markSize, 1);
            ctx.fillRect(xmin, glyphBaseline, 1, markSize);
            ctx.fillRect(xmax, glyphBaseline, markSize, 1);
            ctx.fillRect(xmax, glyphBaseline, 1, markSize);
            ctx.textAlign = 'center';
            ctx.fillText('0', xmin, glyphBaseline + markSize + 10);
            ctx.fillText(glyph.advanceWidth, xmax, glyphBaseline + markSize + 10);

            // styling of the glyph
            ctx.fillStyle = '#000';
            var path = glyph.getPath(x0, glyphBaseline, glyphSize);
            path.fill = '#000';
            path.stroke = 'transparent';
            path.strokeWidth = 0;
            this.drawPathWithArrows(ctx, path);
            //glyph.drawPoints(ctx, x0, glyphBaseline, glyphSize);
        },

        drawPathWithArrows: function drawPathWithArrows(ctx, path) {
            //var _this = this;
            var i, cmd, x1, y1, x2, y2;
            var arrows = [];
            ctx.beginPath();
            for (i = 0; i < path.commands.length; i += 1) {
                cmd = path.commands[i];
                if (cmd.type === 'M') {
                    if (x1 !== undefined) {
                        arrows.push([ctx, x1, y1, x2, y2]);
                    }
                    ctx.moveTo(cmd.x, cmd.y);
                } else if (cmd.type === 'L') {
                    ctx.lineTo(cmd.x, cmd.y);
                    x1 = x2;
                    y1 = y2;
                } else if (cmd.type === 'C') {
                    ctx.bezierCurveTo(cmd.x1, cmd.y1, cmd.x2, cmd.y2, cmd.x, cmd.y);
                    x1 = cmd.x2;
                    y1 = cmd.y2;
                } else if (cmd.type === 'Q') {
                    ctx.quadraticCurveTo(cmd.x1, cmd.y1, cmd.x, cmd.y);
                    x1 = cmd.x1;
                    y1 = cmd.y1;
                } else if (cmd.type === 'Z') {
                    arrows.push([ctx, x1, y1, x2, y2]);
                    ctx.closePath();
                }
                x2 = cmd.x;
                y2 = cmd.y;
            }
            if (path.fill) {
                ctx.fillStyle = path.fill;
                ctx.fill();
            }
            if (path.stroke) {
                ctx.strokeStyle = path.stroke;
                ctx.lineWidth = path.strokeWidth;
                ctx.stroke();
            }
            ctx.fillStyle = '#000000';
        },
        watchFont: function () {
            this.renderGlyph();
        }.observes('font')
    });
});