define("fontdrop/helpers/clean-spaces", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.cleanSpaces = cleanSpaces;

	// this helper removes spaces and special characters from a string

	function cleanSpaces(params /*, hash*/) {

		return params.join("").replace(/[^A-Z0-9]/ig, "");
	}

	exports.default = Ember.Helper.helper(cleanSpaces);
});