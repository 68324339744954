define('fontdrop/components/highlight-bar', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		showBanner: 'showHighlight',

		actions: {
			highlightBanner: function highlightBanner() {
				this.set('showBanner', 'hideHighlight');
			}
		}

	});
});