define('fontdrop/controllers/application', ['exports', 'fontdrop/statics/otFeatures', 'fontdrop/statics/languageData'], function (exports, _otFeatures, _languageData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        elementId: 'fontDropApp',
        queryParams: ['darkmode'],
        darkmode: false,

        init: function init() {
            this._super();

            this.set('otFeatures', _otFeatures.default.otFeatures);
            this.set('languageData', _languageData.default.languageData);

            Ember.run.next(this, function () {
                Ember.$('body > .ember-view').addClass('fontdrop-app');
                // Dark Mode Query
                // firstcheck if browser or OS has prefers-color-scheme: dark
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    this.set('darkmode', true);
                }
                if (this.get('darkmode') === true) {
                    document.getElementById("fontDropBody").classList.add('dark');
                }
            });
        },

        actions: {
            darkmode: function darkmode() {
                if (this.get('darkmode') === false) {
                    this.set('darkmode', true);
                    document.getElementById("fontDropBody").classList.remove('light-mode');
                    document.getElementById("fontDropBody").classList.add('dark');
                } else if (this.get('darkmode') === true) {
                    this.set('darkmode', false);
                    document.getElementById("fontDropBody").classList.remove('dark');
                    document.getElementById("fontDropBody").classList.add('light-mode');
                }
            }
        }

    });
});