define('fontdrop/components/languages-details', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Component.extend({

		classNames: ['languageReport'],
		fontLanguages: [],
		fontLanguagesArray: [],
		fontUniIndex: [],

		didInsertElement: function didInsertElement() {
			this.$().foundation(); //or Ember.$(document).foundation();
		},


		detectLanguage: function () {
			// Get unicode list from font
			var glyphs = this.get('fontOne.glyphs.glyphs');
			var fontUniIndex = [];
			Object.keys(glyphs).forEach(function (key) {
				var val = glyphs[key]['unicodes'];
				if (val !== 'undefined' && val.length > 0) {
					fontUniIndex.push.apply(fontUniIndex, _toConsumableArray(val));
				}
			});
			var langData = this.get('languageData');
			var allLanguagesData = [];
			var allLanguagesArray = [];

			// Get langindex for languages and check if in fonts unicode list
			Object.keys(langData).forEach(function (key) {
				var langindex = langData[key]["langindex"];
				// Function for checkin if something matches
				var isSuperset = langindex.every(function (val) {
					return fontUniIndex.indexOf(val) >= 0;
				});

				if (isSuperset === true) {
					allLanguagesData.push(langData[key]);
					allLanguagesArray.push(langData[key]["name"]);
				}
			});
			this.set('fontLanguages', allLanguagesData);
			this.set('fontLanguagesArray', allLanguagesArray);
			this.set('fontUniIndex', fontUniIndex);
		}.observes('fontOne'),

		actions: {
			scrollTo: function scrollTo(anchor) {
				// Leerzeichen und Sonderzeichen rausnehmen ...
				// anchor.replace(/[^A-Z0-9]/ig, "");
				var elem = '#' + anchor.replace(/[^A-Z0-9]/ig, "");
				document.querySelector(elem).scrollIntoView({
					behavior: 'smooth'
				});
			},
			scrollToDatabase: function scrollToDatabase(anchor) {
				var elem = '#database-' + anchor.replace(/[^A-Z0-9]/ig, "");
				document.querySelector(elem).scrollIntoView({
					behavior: 'smooth'
				});
			}
		}

	});
});