define('fontdrop/controllers/languages', ['exports', 'fontdrop/statics/languageData'], function (exports, _languageData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        application: Ember.inject.controller(),

        fontOne: null,

        init: function init() {
            this.set('languageData', _languageData.default.languageData);
        },

        actions: {
            changeFontOne: function changeFontOne(fontOne) {
                this.set('fontOne', fontOne);
            }
        }

    });
});