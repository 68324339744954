define('fontdrop/helpers/waterfall-style', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.waterfallStyle = waterfallStyle;
  function waterfallStyle(params /*, hash*/) {

    return Ember.String.htmlSafe('font-size:' + params + 'px');
  }

  exports.default = Ember.Helper.helper(waterfallStyle);
});