define('fontdrop/components/font-variable-font', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        classNames: ['variableFontArea'],
        classNameBindings: ['isActive:variableFontOn:variableFontOff'],
        isActive: false,
        isVariable: false,
        vfInstances: [],
        instancesCount: '0',

        variableFont: function () {
            var font = this.get('font');
            /* global VariableFont */
            var vf = new VariableFont(font);
            if (vf.getAxesCount()) {
                this.set('isVariable', true);
                this.set('isActive', true);
            } else {
                this.set('isVariable', false);
                this.set('isActive', false);
            }
            this.makeSlider(vf);
        }.observes('font'),

        makeSlider: function makeSlider(vf) {
            var currentSettings = [];
            var settingsStrings = [];
            var html;

            // if font is a variable font, add named instances dropdown and sliders
            if (vf.getAxesCount()) {

                html = '';

                // add slider controls for each axis
                html += '<div id="slidecontainer" class="small-12 large-6 columns">' + '<p>This is a <b>variable font</b> with ' + vf.getAxesCount() + ' axes. <span id="currentSetting" class="note"></span> </p><table>';
                for (var j = 0; j < vf.getAxesCount(); j++) {
                    var axis = vf.getAxis(j);
                    html += '<tr><td align="right">' + axis.minValue.toString() + '</td><td>' + '<input type="range" min="' + axis.minValue.toString() + '" max="' + axis.maxValue.toString() + '" value="' + axis.defaultValue.toString() + '" class="rslider" id="slider' + j.toString() + '" step="0.1"></td><td>' + axis.maxValue.toString() + '</td><td>' + axis.name.en + '</td></tr>';
                    currentSettings.push("'" + axis.tag + "' " + axis.defaultValue.toString());
                    settingsStrings.push(" " + axis.name.en + ": " + axis.defaultValue.toString());
                }
                html += '</table>';

                // add named instances to select        
                var getInstancesCount = vf.getInstancesCount();
                this.set('instancesCount', getInstancesCount);
                var allInstances = vf.getInstances(); // get instances field from fvar table.
                this.set('vfInstances', allInstances);

                var element = document.getElementById("variable-font-stuff");
                if (element) {
                    element.innerHTML = html;
                }
            } else {
                html = ' ';
                var emptyElement = document.getElementById("variable-font-stuff");
                if (emptyElement) {
                    emptyElement.innerHTML = html;
                }
            }

            // update the settings when any slider values change
            Ember.$('input[type=range]').on('input', function () {
                currentSettings = [];
                settingsStrings = [];
                for (var k = 0; k < vf.getAxesCount(); k++) {
                    var axis = vf.getAxis(k);
                    var theSlider = 'slider' + k.toString();
                    var styleElement = document.getElementById(theSlider);
                    currentSettings.push("'" + axis.tag + "' " + styleElement.value);
                    settingsStrings.push(" " + axis.name.en + ": " + styleElement.value);
                }
                if (vf.getAxesCount()) {
                    Ember.$("#currentSetting").text('Current: ' + settingsStrings.join());
                }
                Ember.$(".droppedFont").css('font-variation-settings', currentSettings.join());
            });
        },

        actions: {
            // apply the named instance settings to the sample text
            setNamedInstance: function setNamedInstance(id) {
                var font = this.get('font');
                var vf = new VariableFont(font);
                var vfInstance = this.get('vfInstances');
                var instanceNumber = vfInstance.findIndex(function (x) {
                    return x.name.en === id;
                });
                // console.log(instanceNumber);   
                var settings = vf.getNamedInstanceSetting(instanceNumber);
                Ember.$(".droppedFont").css('font-variation-settings', settings);
                Ember.$("#currentSetting").text('Current: ' + vf.getInstanceName(instanceNumber));
                // update the slider values
                var coords = vf.getNamedInstance(instanceNumber).coordinates;
                var i = 0;
                for (var tag in coords) {
                    //set slider value to coordinate string
                    Ember.$('#slider' + (i++).toString()).val(coords[tag].toString());
                }
            }
        }
    });
});